import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  MainContent: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    //overflowX: 'hidden',
  },
  image: {
    maxWidth: '100%',
  },

  MainBox: {
    marginBottom: '20%'
  },
}));