import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ClassIcon from '@material-ui/icons/Class';

//import StairsIcon from '@material-ui/icons/Stairs';
//import HandshakeIcon from '@mui/icons-material/Handshake';
//import HandymanIcon from '@material-ui/icons/HandymanIcon';

import useStyles from './styles'; 
import DrawerTitleContainer from '../../components/FixedDrawerMenu/DrawerTitleContainer';
import {TiltingImageReplica } from '../../components'

import BannerImage from '../../components/BannerImage/BannerImage';

//► 

const WorkHistoryPage = () => {
    const classes = useStyles();
    const container_details = {
        title: 'Work History',
        emoji: '💬',
    };
    
    document.getElementsByTagName("title")[0].innerHTML = container_details.title + " | Zach Happel";

    const directory = [
        { title: 'About Me', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
        { title: 'Experience', componentName: 'Services', path: '/experience', icon: <BuildIcon/> },
        { title: 'Work History', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/work', icon: <ComputerIcon/> },
        { title: 'Projects', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/projects', icon: <ClassIcon/> },
        { title: 'Presentation and Events', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/presentation-events', icon: <PeopleOutlineIcon/> },
        //{ title: 'Other', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
        
      ]
  
    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    localStorage.setItem('current_page', 'WorkHistoryPage');
    
    const getContent = () => {
        return (

            <React.Fragment>



                

              

                    <div className={classes.boxContent}>

                        <div className={classes.subsectionTitleAndBody}>

                          
                            
                            <h4 className={classes.epH4}> Tech Related </h4>
                               

                            
                        </div>

                    </div>
                


            </React.Fragment>

        )
    };

    const content = getContent();
    const returnEmptyJSX = () => {

    }
    
    const [checked, setChecked] = React.useState(false);
    return (
        <>
         <DrawerTitleContainer 
                {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: returnEmptyJSX(),
                    onchange: {handleChange}
                    
                }}
                />
       
           
            
       <Container className={classes.whatCanWeDoForYouContainer} style={{display: 'inline-block'}}>
                            
        <Paper style={{
                backgroundColor: '#1A2027',
                paddingLeft: '0%',
                textAlign: 'left',
                paddingBottom: '5%', 
                boxShadow: '0px 2px 1px -1px rgb(125 125 125 / 20%), 0px 1px 1px 0px rgb(255 222 222 / 2%), 0px 1px 3px 0px rgb(125 115 115 / 51%)',
                
            }}>

                            <Divider  style={{marginLeft: '2rem', marginRight: '1rem',  marginTop: '2rem'}} />

                           

                          
                            
                                <h4 className={classes.epH4} style={{display:'block'}}> Tech Related </h4>
                            <Divider className={classes.Divider} id={'GlowingDivider'} style={{marginLeft:'1rem', marginRight: '1rem', marginTop:'0rem'}} />
   



                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                            
                                            <div className={classes.JobTitleAndLocationContainer}>
                                                
                                                <div className={classes.JobTitleContainer}> 
                                                    <p className={classes.JobTitle}> <span className={classes.JobCompany}> Happel Solutions</span>, Owner & Operator </p>
                                                </div>
                                                
                                                <div className={classes.DateAndLocationContainer}>
                                                        <p className={classes.Location}> October 2021 - Current</p>
                                                        <p className={classes.Location}> Centerport, New York</p>
                                                </div>

                                           </div>

                                        </div>
                                       
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            
                                            Founded and currently operating a technology solutions company specializing in various forms of development, tech. services, and consulting. 
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.sectionBackgroundOther}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            <ul className={classes.List}>  
                                            
                                                <li className={classes.ListItem} id="ListItem"> Building websites, APIs, and web applications. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Exclusively used <span className={classes.HighlightedText}>React</span> → developed functional components,  class components. Used JSX, conditional rendering, inline styling, and more.</li>
                                                <li className={classes.ListItem} id="ListItem"> Created an ECommerce site a scheduling tool which grabs and allocates timeslots as are available during certain dates  </li>
                                                <li className={classes.ListItem} id="ListItem"> Created a scheduling tool which grabs and allocates timeslots as are made available by the site owner via Google Calendar. </li>
                                                <li className={classes.ListItem} id="ListItem"> Integrated <span className={classes.HighlightedText}>Material UI</span> (MUI) external component library. Utilized component APIs, MUI themes, etc. </li> 
                                                <li className={classes.ListItem} id="ListItem"> UX, UI, styling, graphics, animation. Designed and styled webpages. Used various tech including <span className={classes.HighlightedText}>Gulp</span>, <span className={classes.HighlightedText}>SASS</span>   </li> 
                                                <li className={classes.ListItem} id="ListItem"> Hosting using <span className={classes.HighlightedText}>Google Cloud</span>. Experience includes communication between several App Engine instances, flex environments for automated scaling, networking configuration  </li> 
                                                <li className={classes.ListItem} id="ListItem"> <span className={classes.HighlightedText}>Google Analytics</span> and <span className={classes.HighlightedText}>Google Tag Manager</span> for monitoring of site statistics, pageviews, scroll-depth, time-on-page, etc.  </li> 
                                                <li className={classes.ListItem} id="ListItem"> Created all logos, marketing materials, social media pages, physical promotional items.</li> 
                                                <li className={classes.ListItem} id="ListItem"> Handled client outreach and managed business contacts. </li> 
                                            </ul>
                                                                                      </div>
                                        </div>
                                    </div>

                                   

                            <Divider className={classes.Divider} id={'GlowingDivider'} />


                            <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                            
                                            <div className={classes.JobTitleAndLocationContainer}>
                                                <div className={classes.JobTitleContainer}> 
                                                    <p className={classes.JobTitle}> IBM, Joint-Study Intern </p>
                                                </div>
                                                <div className={classes.DateAndLocationContainer}>

                                                        <p className={classes.Location}> May 2019 - July 2020</p>
                                                        <p className={classes.Location}> Poughkeepsie, New York</p>

                                                </div>
                                           </div>

                                        </div>
                                       
                                    </div>
                                </div>

                            <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}   >
                                            Project lead and head developer of the development and implementation of IBM's Trusted Identity: a virtual form of 
                                            identification that utilizes blockchain as the basis for forming trusted relationships. (Currently known as IBM Verify Credentials)

                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.sectionBackgroundOther}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            <ul className={classes.List}  style={{paddingLeft: '1rem!important'}}>  
                                                <li className={classes.ListItem} id="ListItem"> Primarily used <span className={classes.HighlightedText}>Nodejs</span>. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Developed using <span className={classes.HighlightedText}>`openssi-websdk,`</span> IBM's Verified Credential Acount Services API, to develop APIs</li> 
                                                <li className={classes.ListItem} id="ListItem"> Shaped the approach methodology for how Trusted Identity was to be implemented, e.g. P2P interactions, POS system integrations, queue/line processing, local business integration. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Developed Marist's backend card-issuance and verification APIs which integrate with `openssi-websdk, IBM's Verified Credential Acount Services API</li> 
                                                <li className={classes.ListItem} id="ListItem"> Responsible for all communications made between Marist's server hosting the API and  </li> 
                                                <li className={classes.ListItem} id="ListItem"> Developed core backend API allowing for Marist College to issue credentials to students</li> 
                                                <li className={classes.ListItem} id="ListItem"> Showcasing the project's progress incrementally, locally cloned websites featured the developed web solutions. Additionally, these solutions were made accessible to the shareholders for purposes of their interaction during meetings.</li>
                                                <li className={classes.ListItem} id="ListItem"> Implemented Openss   </li> 
                                                <li className={classes.ListItem} id="ListItem"> Provided feedback on the UI/UX of the mobile application being developed, via a form of user testing </li> 
                                                <li className={classes.ListItem} id="ListItem"> Worked onsite at IBM Joint-Study research facility on campus at Marist College </li> 
                                                <li className={classes.ListItem} id="ListItem"> Remote communication and teamwork alongside IBM team in Raleigh, North Carolina</li> 
                                                <li className={classes.ListItem} id="ListItem"> One of eight students partaking in the internship program across all of campus.</li> 
                                            </ul>
                                                                                      </div>
                                        </div>
                                    </div>

                            <Divider className={classes.Divider} id={'GlowingDivider'} />


                           
                            <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                            
                                            <div className={classes.JobTitleAndLocationContainer}>
                                                <div className={classes.JobTitleContainer}> 
                                                    <p className={classes.JobTitle}> IT Aide Intern @ Cold Spring Harbor Laboratory </p>
                                                </div>
                                                <div className={classes.DateAndLocationContainer}>

                                                        <p className={classes.Location}> October 2018 - Current</p>
                                                        <p className={classes.Location}> Cold Spring Harbor, New York</p>

                                                </div>
                                           </div>

                                        </div>
                                       
                                    </div>
                                </div>

                            <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            Working for the Meetings & Courses department, it was the responsibility of myself and two other collegues that the and one other intern were responsible for 
                                           </div>
                                        </div>
                                    </div>
                                    <div className={classes.sectionBackgroundOther}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            <ul className={classes.List}>  
                                                <li className={classes.ListItem} id="ListItem"> Daily communication, collaboration, and accomadation to some of the academia's most renowned and respected instructors </li> 
                                                <li className={classes.ListItem} id="ListItem"> Installed entire computer laboratories, including medical equipment for course instructors.</li> 
                                                <li className={classes.ListItem} id="ListItem"> Installed software almost exclusively using OSx terminal, shell console, command line. Additionally installed packages. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Automated printer level checking process by writing Python script which directly communicated with all printers across the campus's intranet. Automated via CHRON job.</li> 
                                                <li className={classes.ListItem} id="ListItem"> Created the Meeting and Courses live streaming website, only accessible via campus intranet, which displayed live broadcasts- interviews, conferences - across the campus's televisions. Using standard Javascript, HTML, CSS I developed a feature which would activate automatic playback of Cold Spring Harbor's YouTube channel when there was no active broadcast. Displayed videos within the playlist, up next, and looped the videos. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Upgraded computer hardware in both Mac and Windows machines. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Aided audio and video department in troubleshooting live streaming, audio and video, and projector issues.</li> 
                                                <li className={classes.ListItem} id="ListItem"> On-call during entire shift and had to immediately travel across campus via golf cart to assist in person, when from-office remote methods would be insufficient.</li> 
                                               
                                            </ul>
                                                                                      </div>
                                        </div>
                                    </div>
                            
                            <Divider className={classes.Divider} id={'GlowingDivider'} />


                     

                            <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                            
                                            <div className={classes.JobTitleAndLocationContainer}>
                                                <div className={classes.JobTitleContainer}> 
                                                    <p className={classes.JobTitle}> Student Data Analyst, Data Science and Analytics Department </p>
                                                </div>  
                                                <div className={classes.DateAndLocationContainer}>

                                                        <p className={classes.Location}> October 2020 - Current</p>
                                                        <p className={classes.Location}> Poughkeepsie, New York </p>

                                                </div>
                                           </div>

                                        </div>
                                       
                                    </div>
                                </div>

                            <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                            I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.sectionBackgroundOther}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            <ul className={classes.List}>  
                                                <li className={classes.ListItem} id="ListItem"> Developed API which would receive, from and outside location, realtime medical data. Additionally taking said received data and transmitted to internal systems where the data would be processed and fed into ML model.</li> 
                                                <li className={classes.ListItem} id="ListItem"> Familiarized with Marist's entire data storage structure and architecture, with addition to it's integration with iLearn, Banner student portal. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Participated in discussion and the initial migration of data to different type of database system </li> 
                                                <li className={classes.ListItem} id="ListItem"> Dealt exclusively with confidential medical and student data, all requiring that I sign Non-Disclosure Agreements (NDAs) </li> 
                                                <li className={classes.ListItem} id="ListItem"> Was familiarized with the model used for generating academic warning predictions, how it was developed and how realtime student data was used in generating it's predictions. </li> 
                                            </ul>
                                                                                      </div>
                                        </div>
                                    </div>
                            
                            <Divider className={classes.Divider} id={'GlowingDivider'} />

                            <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                            
                                            <div className={classes.JobTitleAndLocationContainer}>
                                                <div className={classes.JobTitleContainer}>
                                                    <p className={classes.JobTitle}> Center for Cybersecurity and Technology Intern </p>
                                                </div>
                                                <div className={classes.DateAndLocationContainer}>

                                                        <p className={classes.Location}> October 2020 - Current</p>
                                                        <p className={classes.Location}> Plattsburgh, New York </p>

                                                </div>
                                           </div>

                                        </div>
                                       
                                    </div>
                            </div>

                            <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                           Emersed in an environment which provided opportunity to learn and practice cybersecurity related skills. In particular susceptible attack vectors, methods of attack, defensive measures,                                            </div>
                                        
                                           <div className={classes.epDescriptionIndentForDescription}  >
                                           recognition of attack and breach, organizational response, etc.                                       </div>
                                        </div>
                                        
                                    </div>
                                    <div className={classes.sectionBackgroundOther}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription}  >
                                            <ul className={classes.List}>  
                                                <li className={classes.ListItem} id="ListItem"> Alongside Dr. Zareef Muhammed, worked on research purposed identifying connection between email criteria/composition and its correlation to a person's susceptibility to engage in an embedded phishing link. Using an EEG, effectiveness of stimuli was measured via brainwave activity. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Attended IBM in Cambridge, Massachusetts and participated in the X-Force Cyber Range. Throughout the exercise, myself and other interns were exposed to realistic breach scenarios as we sat in a command center. Emphasis of the program was to put into practice proper protocol for dealing with a breach within your organization. One unique aspect was the inclusion of handling media and PR responses to the incident in a calm and composed manner.  </li> 
                                                <li className={classes.ListItem} id="ListItem"> Had weekly meetings in which cybersecurity professionals gave presentations regarding their responsibilities and what is to be expected of us as we develop as professionals ourselves. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Practiced performing exploits on purposefully vulnerable servers that we had set up. </li> 
                                                <li className={classes.ListItem} id="ListItem"> Used tools native to Kali Linux.</li> 
                                            </ul>
                                                                                      </div>
                                        </div>
                                    </div>

                           
                            
                            <Divider className={classes.Divider} id={'GlowingDivider'} />



                        
                            <h4 className={classes.epH4}> Other </h4>
                            <div className={classes.epDescriptionIndent1}
                                 style={{marginTop: '0vh'}}
                            >
                                    <div className={classes.sectionBackgroundPink}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                            <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginTop: '0.5rem', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                            Coming soon...
                                            </div>
                                        
                                           
                                        </div>
                                        
                                    </div>
                                

                           

                            </div>
                            </Paper>
                        </Container>
        
        </>
    )
}

export default WorkHistoryPage;


/**
 * <div className={classes.epDescriptionIndent1}>
                                <h4 className={classes.epH4}
                                style={{marginLeft:'0rem'}}> Work History </h4>
                                
                            </div>
 */

                            /**
                             * <BannerImage style={{
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                }}/>
                             */
                    

                /**
                 * <Typography className={classes.JobTitle}>
                                                            Happel Solutions
                                                    </Typography>
                                                    <Typography className={classes.Location}>
                                                            October 2021
                                                            Centerport, New York
                                                    </Typography>
                 */

                                                    /**
                                                     * 
                                <div className={classes.sectionBackgroundWhite}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                            <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                                
                                                <div className={classes.JobTitleAndLocationContainer}>
                                               <p className={classes.JobTitle}> Neurological, Cybersecurity Phishing Research </p>
                                               
                                                    <div className={classes.DateAndLocationContainer}>

                                                            <p className={classes.Location}> January 2018 - June 2018</p>
                                                            <p className={classes.Location}> Plattsburgh, New York </p>

                                                    </div>
                                               </div>

                                            </div>
                                           
                                        </div>
                                    </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription}  >

                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem} id="ListItem"> </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider className={classes.Divider} id={'GlowingDivider'} />


                              
                                <div className={classes.sectionBackgroundWhite}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 8%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                            <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                                
                                                <div className={classes.JobTitleAndLocationContainer}>
                                               <p className={classes.JobTitle}> Microclimates and Toxic/Flammable Chemical Drift Research </p>
                                               
                                                    <div className={classes.DateAndLocationContainer}>

                                                            <p className={classes.Location}> October 2020 - Current</p>
                                                            <p className={classes.Location}> Plattsburgh, New York </p>

                                                    </div>
                                               </div>

                                            </div>
                                           
                                        </div>
                                    </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem} id="ListItem"> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                    <li className={classes.ListItem} id="ListItem"> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider className={classes.Divider} id={'GlowingDivider'} />

                                                     */