import React, { useState, useEffect } from 'react';
import './assets/css/default.min.css';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

import Site from "./SitePages/Site"
//import { ExperiencePage, WorkHistoryPage } from './SitePages';
import ExperiencePage from './SitePages/ExperiencePage/ExperiencePage';
import WorkHistoryPage from './SitePages/WorkHistory/WorkHistoryPage';
import ProjectsPage from './SitePages/ProjectsPage/ProjectsPage';
import NewProjectPage from './SitePages/NewProjectsPage/NewProjectsPage';
import PresentationAndEventsPage from './SitePages/PresentationAndEventsPage/PresentationAndEventsPage';


//import { Navbar } from './components';
const App = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  //ReactGA.initialize('G-B4RTYC9CEW');
  ReactGA.initialize('356523487');
  //ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  const tagManagerArgs = {
    gtmId: 'GTM-WS4D3HK'
  };

  TagManager.initialize(tagManagerArgs);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);


    
  const directory = [
    { title: 'About Me', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
    { title: 'Experience', componentName: 'Services', path: '/experience', icon: <BuildIcon/> },
    { title: 'Work History', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/work', icon: <ComputerIcon/> },
    { title: 'Projects', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/projects', icon: <ComputerIcon/> },
    { title: 'Presentation and Events', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/presentation-events', icon: <ComputerIcon/> },
    { title: 'Other', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
    
  ]
  

  return (
    <Router>
     
        <CssBaseline />

        <Routes>
          <Route  path="/" element={<Site/>} />
          <Route  path="/about" element={<Site/>} />
          <Route path="/experience" directory={directory} element={<ExperiencePage/>} />
          <Route path="/work" directory={directory} element={<WorkHistoryPage/>} />
          <Route path="/projects" directory={directory} element={<NewProjectPage/>} />
          <Route path="/presentation-events" directory={directory} element={<PresentationAndEventsPage/>} />
        </Routes>
   
    </Router>
  );
};

export default App;
//        <Navbar />
//        <Navbar /> 
//<Route  path="/about" element={<Site/>} />