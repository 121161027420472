import React from 'react'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import useStyles from './styles'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#1A2027',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));




const SubItem = ({name, items}) => {
    const classes = useStyles();
  return (
    <>
       
        
            {items.map((content,index) => {
                return (
                    <Item id={index} style={{backgroundColor: '#1A2027'}}> {content} </Item>
                )
            })}
            
         
    </>
  )
}

export default SubItem