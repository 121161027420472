import React, {Component, Fragment, useEffect, useState} from 'react';
import HomePage from './HomePage/HomePage';
import { Navbar } from '../components';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import ClassIcon from '@material-ui/icons/Class';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FixedDrawerMenu from '../components/FixedDrawerMenu/FixedDrawerMenu';
import { Title } from '@material-ui/icons';
import TitleContainer from '../components/TitleContainer/TitleContainer';
import DrawerTitleContainer from '../components/FixedDrawerMenu/DrawerTitleContainer';
import useStyles from './styles'; 
import Paper from "@material-ui/core/Paper";
import AboutMePage from './AboutMePage/AboutMePage';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const Site = () => {
  
  const directory = [
    { title: 'About Me', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
    { title: 'Experience', componentName: 'Services', path: '/experience', icon: <BuildIcon/> },
    { title: 'Work History', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/work', icon: <ComputerIcon/> },
    { title: 'Projects', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/projects', icon: <ClassIcon/> },
    { title: 'Presentation and Events', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/presentation-events', icon: <PeopleOutlineIcon/> },
    //{ title: 'Other', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
    
  ]
  
  const page_names = ['ExperiencePage', 'ProjectsPage', 'PresentationsAndEventsPage', 'WorkHistoryPage']
 
  
  useEffect(() => {

    // Fixes jquery not re-inserting itself when the page router changes back to HomePage
    // Originally, when the site loads, it loads on / and the jquery inserts class tps-section (Which contains the portrait photo of myself)
    var last_page = localStorage.getItem('current_page');
    console.log( "Last Page:  " + last_page);
    if (page_names.includes(last_page)) {
      localStorage.setItem('current_page', 'HomePage');
      window.location.reload(true);
    }
    
  
  }, []);

  const classes = useStyles();

  const container_details = {
    title: 'About Me',
    emoji: '💬',
};


/*** PASS THROUGH TO ABOUT ME PAGE
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
  return ( 


    <>
        
        <main>
            <HomePage directory={directory}/>
        </main>
    
    </>
    
  );
}

export default Site


/** <TitleContainer { ... {
      classes: classes,
       directory: directory,
       title: "Balls", 
       emoji:
    }} /> */