import React, { Fragment, useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container'

import { styled } from '@mui/material/styles';

import makeStyles from './styles'
import {  Typography } from '@mui/material';

const Item = styled(Paper)(({ theme, marginTop }) => ({
    backgroundColor: '#1A2027',
    color: 'rgb(255 255 255 / 91%)',
    boxShadow: '0px 2px 1px -1px rgb(125 125 125 / 19%), 0px 1px 1px 0px rgb(255 222 222 / 2%), 0px 1px 3px 0px rgb(125 115 115 / 13%)',
    padding: theme.spacing(1),
    textAlign: 'center',
    marginTop: marginTop,
    //color: theme.palette.text.secondary,
  }));




  


const ProjectsContainer = ({name, content}) => {
    //console.log("Details: " + content);

    const classes= makeStyles();
    //const classes = useStyles();
    return (
    <Container  style={{marginBottom: '5vh'}}>
        <Paper style={{
            backgroundColor: '#1A2027',
            paddingLeft: '0%',
            textAlign: 'left',
            paddingBottom: '5%', 
            boxShadow: '0px 2px 1px -1px rgb(125 125 125 / 20%), 0px 1px 1px 0px rgb(255 222 222 / 2%), 0px 1px 3px 0px rgb(125 115 115 / 51%)',
             
        }}>
            <Typography
                style={{
                    textAlign:'center',
                    paddingLeft: '1%', 
                    fontFamily: 'CartographCF-RegularItalic', //'AvenirLTStd-Book', 
                    color: 'rgb(255 255 255 / 91%)',
                    paddingTop: '1%',
                }}>
                    {name}
            </Typography>
            
            <Stack  spacing={2} style={{padding: '1%'}}className={classes.DetailsTypography} > 
            
                {content.map( (item, index) => {
                    //console.log("Item: " + item.details);
                    return (
                        (index === 0 ? (
                            <Item id={index} marginTop={'11px'}> 
                            <Typography 
                            className={classes.DetailsTypography}
                            style={{     
                               
                                
                                paddingLeft: '1%',
                                listStyleType: 'disclosure-closed',
                                display: 'list-item',
                                listStyle: 'inside',
                                textAlign: 'left',
                                fontFamily: 'AvenirLTStd-Book',
                                //fontSize: '0.8rem',
                             }}> 
                                 {item.details} 
                             </Typography>
                            </Item>
                         ) : 
                        <Item id={index}> 
                           <Typography
                            className={classes.DetailsTypography}
                           style={{     
                               paddingLeft: '1%',
                               listStyleType: 'disclosure-closed',
                               display: 'list-item',
                               listStyle: 'inside',
                               textAlign: 'left',
                               fontFamily: 'AvenirLTStd-Book',
                               //fontSize: '0.8rem',
                            }}> 
                                {item.details} 
                            </Typography>
                           </Item>
                        )
                        
                    )
                })}
                
            </Stack> 
        </Paper>
    </Container>    
  )
}

export default ProjectsContainer