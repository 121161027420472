import React, { useState, useEffect } from 'react';

import Container from '@mui/material/Container'
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
//import HomeIntro from '../HomeIntro/HomeIntro';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button'
import useStyles from './styles'; 
//import { TiltingImage} from '../..';
//import { TiltingImageReplica } from '../..';
import BannerImage from '../../components/BannerImage/BannerImage';
import {TiltingImageReplica } from '../../components'
import TitleContainer from '../../components/TitleContainer/TitleContainer';
import DrawerTitleContainer from '../../components/FixedDrawerMenu/DrawerTitleContainer';
import nellaheadshot from '../../assets/nellaheadshot1.jpg';

//import nellaheadshot from '../../../assets/nellaheadshot1.jpg';
const HomePage = ({directory}) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [load, setLoad] = React.useState(false);

    //const { directory } = this.props.directory;

    
    const handleChange = () => {
        setChecked((prev) => !prev)
    }
    
    useEffect(() => {   
        if (checked) {
            let storeval = localStorage.getItem('mainImageLoaded');
            console.log('Storage Value: ', storeval);
        }
    })
    
    
    const container_details = {
        title: 'About Me',
        emoji: '💬',
    };
    
    document.getElementsByTagName("title")[0].innerHTML = container_details.title + " | Zach Happel";
    
    const imgc = (
        <div>
            Balls Magee
        </div>

    );

    const fadingImage = (
        <Box sx={{ display: 'flex', bgcolor: '#cfe8fc'}} >
                             <img className={classes.image} src={nellaheadshot} alt="nellaheadshot"/>
        </Box>
    )

    /**
    const getContent = () => {

        const container_details = {
            title: 'Website Showcase',
            emoji: '💬',
        };
        
        return (

            <React.Fragment>

            

                <BannerImage style={{   
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                }}/>

                <div className={classes.sectionBackground}>

                    <div className={classes.boxContent}>

                        <div className={classes.subsectionTitleAndBody}>

                            <div className={classes.sectionBackgroundWhite}>

                                <div className={classes.boxContent}
                                style={{
                                    boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',
                                }}>

                                    <div className={classes.epDescriptionIndentForOpener}
                                         style={{
                                             textAlign: 'center',
                                             marginBottom: '0.5rem',
                                         }}
                                    >
                                        Happel Solutions is a technology solutions company specializing in various forms
                                        of development, tech services, and consultation options.
                                    </div>
                                </div>

                            </div>
                            
                            <div className={classes.epDescriptionIndent1}>
                                <h4 className={classes.epH4}
                                style={{marginLeft:'0rem'}}> What can we do for you? </h4>
                                <div className={classes.epListLeft}
                                     style={{
                                         marginLeft: '1rem',
                                         listStyle: 'circle'

                                     }}>
                                    <li>Website Development</li>
                                    <li>Software Development</li>
                                    <li>Data Backup Solutions</li>
                                    <li>Consulation Services</li>
                                    <li>Social Media Management</li>
                                    <li>and more</li>
                                </div>
                            </div>

                            <div className={classes.whatCanWeDoForYouContainer} style={{display: 'inline-block'}}>
                            <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>
                                <h4 className={classes.epH4}> Our Goal </h4>
                                <div className={classes.epDescriptionIndent1}
                                     style={{marginTop: '0vh'}}
                                >


                                    Happel Solutions provides businesses, artists, and freelancers with technological
                                    solutions that fortify existing business practices and customer bases.
                                    Transitioning and adapting established, proven business practices into their
                                    respective digital analogs is a practice that can be trying and difficult given
                                    a lack of understanding. Equipped with a large degree of varied experience,
                                    Happel Solutions' approach to development permits an unmitigated sense of security
                                    and satisfaction via the means of both clear and concise communication with it's
                                    clients; of which comes coupled with a respect for time-sensitive milestones.
                                    <br/>
                                    <br/>
                                    <Divider style={{ marginRight: '6rem'}}/>
                                    <i style={{position: 'absolute'}}> Perfection is our goal, excellence will be tolerated </i> <br/>
                                    <i>~ J. Yahl</i>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </React.Fragment>

        )
    };

**/
    const getHomePageContent = () => {
        
       

    }

  
    const content = getHomePageContent();

    return (
        

        <div className={classes.MainContent}>
       
            <DrawerTitleContainer 
                {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: getHomePageContent(),
                    onchange: {handleChange}
                    
                }}
                />
       
           
            
            <Container maxWidth="md" >
                    

                    <TiltingImageReplica/>
                
            </Container>

            
            
    </div>


        
    );
}

export default HomePage
/**
 * <Box className={classes.MainBox} sx={{ display: 'flex', bgcolor: '#cfe8fc'}} >

                    </Box>
 */
// <div className={classes.toolbar} />
//                        <TiltingImage/>
// <div className={'hio'} style={{backgroundColor: 'red', height: '100%', width: '100%'}}>
//                            <Fade in={checked}>{imgc}</Fade>
//                        <Fade in={true} appear timeout={2000}>{fadingImage}</Fade>


/**
 * <main className={classes.MainContent}>
            <div className={classes.toolbar} />
                
                <Container maxWidth="md">
                        <Box className={classes.MainBox} sx={{ display: 'flex', bgcolor: '#cfe8fc'}} >

                        </Box>

                        <TiltingImageReplica/>
                
                    
                </Container>

                <TitleContainer id={'AboutContainer'} className={classes.IntroContainer}

                    style={{
                        background: '#353535',
                    }}
                    {
                    ...
                        {
                            classes: classes,
                            details: container_details,
                            content: content,
                            onchange: {handleChange}
                        }
                    }
                />
                
        </main>
 */