
import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const styles = theme => ({

    ToolbarTitle: {
        backgroundColor: '#da8d8d !important',
    },

    TitleText: {
        [theme.breakpoints.down(900)]: {
            fontSize: '0.85rem',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.05rem',
        },
    },

});


// Local Storage -- homeHeaderPreviouslyShown
class AppToolBarTitle extends Component{


    constructor(props) {
        super(props);
        this.state = {};
    }



    componentDidMount() {
        //console.log('AppTool')
        //console.log('Title Background Color --  Props: ', this.props.title_background_color);
    }


    render () {
        const { classes } = this.props;
        const {title} = this.props;
        const {emoji} = this.props;
        const double_space_emoji = emoji + "  ";
        //console.log('Double Space Emoji: ', double_space_emoji);

        //console.log("AppToolBar Props: ", this.props);
       // const {bg_color} = this.props;
        //const {classesTwo} = this.props.bg_color.title_background_color;
        //const bg = "#" + bg_color + " !important";
        //console.log('BG COLOR: ', bg);
        const {isMain} = this.props;
        //console.log("Is Main: ", isMain);
        return (


            <AppBar
                className = { isMain ? classes.main_title : classes.secondary_title }
                style =
                    {
                        {
                            position: 'relative',
                            borderTopLeftRadius: '0.5rem',
                            borderTopRightRadius: '0.5rem',
                            color: 'black',
                        }
                    }
            >
                <Toolbar style={{
                    position: 'relative',
                }}>
                    <Typography variant="h6" className={classes.TitleText} style={{
                        display: 'flex',
                        fontFamily: "'Titillium Web',sans-serif",
                        fontWeight: 600,
                        letterSpacing: '2.9px',
                        lineHeight: '2',
                        marginLeft: 'auto',
                        marginRight: 'auto',



                        //background: {bg},
                    }}>
                        {double_space_emoji.replace(/ /g, "\u00a0")}

                        <p style={{
                            //marginLeft: '0.5rem',
                            //marginRight: '1rem'
                        }}>

                        </p>

                        {title} </Typography>
                </Toolbar>
            </AppBar>
        );
    }



}

export default withStyles(styles, { withTheme: false })(AppToolBarTitle);


/**



 **/