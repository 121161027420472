import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppToolBarTitle from "../AppToolBarTitle/AppToolBarTitle";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";


const styles = theme => ({

    ServicesContainer: {
        //minHeight: '22.5rem',
        overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        borderTopColor: "rgb(219, 219, 219)",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderRightColor: "rgb(219, 219, 219)",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "rgb(219, 219, 219)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderLeftColor: "rgb(219, 219, 219)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderImageSource: "initial",
        borderImageSlice: "initial",
        borderImageWidth: "initial",
        borderImageOutset: "initial",
        borderImageRepeat: "initial",
    },

    title_toolbar: {

    },

    ServicesAppBarPaper: {
        backgroundColor: 'transparent',
    }

});


// Local Storage -- homeHeaderPreviouslyShown
class TitledContainer extends Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}




    render () {

        const { classes } = this.props;
        const { details } = this.props;
        const { content } = this.props;

        //console.log('TitledContainer Props: ', this.props);

        return (
            <div className="home-body-container" id={"home-body-container-before"}>

              

                    <AppToolBarTitle className={classes.title_toolbar} {
                        ...
                            {
                                classes: classes,
                                title: details.title,
                                emoji: details.emoji,
                                isMain: true, // ??
                            }
                    } />

                    <Toolbar id={'separating-gutter'} /** Separating! **/ />

                    <Paper className={classes.ServicesAppBarPaper}>

                        {content}

                    </Paper>

               

            </div>

        );
    }



}

export default withStyles(styles, { withTheme: false })(TitledContainer);
