import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
  tiltingImage: {
    width: "100%",
  },
  image: {
    maxWidth: '100%',
  },
}));