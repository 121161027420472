import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
//import ConstructionIcon from '@material-ui/icons/Construction';
//import StairsIcon from '@material-ui/icons/Stairs';
//import HandshakeIcon from '@material-ui/icons/Handshake';
//import AbcIcon from '@material-ui/icons/Abc';
import ClassIcon from '@material-ui/icons/Class';
import LaptopIcon from '@material-ui/icons/Laptop';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

import useStyles from './styles'; 
import DrawerTitleContainer from '../../components/FixedDrawerMenu/DrawerTitleContainer';
import {TiltingImageReplica } from '../../components'
import ExperienceContainer from '../../components/ExperienceContainer/ExperienceContainer';
import SubItem from '../../components/ExperienceContainer/SubItem';
import SortForm from '../NewProjectsPage/SortForm';

const NewProjectsPage = () => {

    const getHomePageContent = () => {
    
    }

    const container_details = {
        title: 'Projects',
        emoji: '💬',
    };

    document.getElementsByTagName("title")[0].innerHTML = container_details.title + " | Zach Happel";
    localStorage.setItem('current_page', 'ExperiencePage');

    const directory = [
        { title: 'About Me', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
        { title: 'Experience', componentName: 'Services', path: '/experience', icon: <BuildIcon/> },
        { title: 'Work History', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/work', icon: <ComputerIcon/> },
        { title: 'Projects', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/projects', icon: <ClassIcon/> },
        { title: 'Presentation and Events', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/presentation-events', icon: <PeopleOutlineIcon/> },

       // { title: 'Other', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
        
     ]
  
    const handleChange = () => {
        setChecked((prev) => !prev)
    }
    
    const classes = useStyles();
    const content = getHomePageContent();
    const [checked, setChecked] = React.useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));


      /** Categories: 
       * Finance
       * Agile Software Development / Project Management
       * Software Development
       * Third-party API Usage
       * Full-Stack
       * React
       * Server
       * Server Hosting
       * Data Management
       * Problem Solving
       * Remote Work
       * Database
       * Node
       * RESTFul API
       * Front-End UX & UI
       * Database Used
       *   **/ 


      /**
       * {
            id: "0c",
            details: "", 
            categories: [""]
        },
       */
    const categories = ["Finance", "Project Management", "Software Development", 
    "API", "Full-Stack", "Servers", "React", "Servers", "Data Management", "Problem Solving", "Remote Work", "Database"]

    const data = [ {
        details: "OPTIMIZING SERVER COST by evaluating performance, functionality, cost - in reference to established requirements", 
        categories: ["Finance", "Project Management"]

    }];

    const pythondata = [ 
        {
            id: "23g20c",
            details: "An automated Reddit bot, TwitterLinkBot, using Optical Character Recognition (OCR) and a trained Machine Learning model, determines if an image is a screenshotted tweet. If it is, text recognized in images are ran against two separate Twitter search algorithms in an attempt to find and deliver a URL to the tweet in the form of a comment response. Ranked 12th best bot of 8,705 bots (at the time.)", 
            categories: ["Finance", "Project Management"]
        },
        {
            id: "0ddqdqc",
            details: "RESTful Flask server working in symbiotic relationship with a primary Node web server to handle the scheduling of selected appointment times, leveraging the Google Calendar API. Due to client-side limitations of Google Calendar API, calendar information may only be requested, not modified. Client-requested appointment timeslot and client information are transmitted to the Flask server, where it then is scheduled, synchronized across multiple calendars, and where relevant alerts set.", 
            categories: ["Finance", "Project Management"]
        },
        {
            id: "qgqw0b",
            details: "Remotely took inventory of printer levels (ink, paper, etc.) across Cold Spring Harbor Laboratory campus printers— of which were accessible across the intranet. Scheduled using CHRON jobs.",
            categories: ["Software Engineering", "Real World Application"]
        }, 
        {
            id: "0a",
            details: "Proxy scraper which uncovers live, unrestricted proxies using two methods. The first, and most cumbersome is the randomized creation of proxies using known IP Ranges such as location-defined and ISP-defined ranges (while of course being cognizant of military and Gov. IP ranges.) The second method requires a proxy list(s) which at the very least is within a few days old. Using a combination of techniques, commonalities can be resolved by identifying repeating patterns within the network and host portions of the IP addresses, as well as the ports.", 
            categories: [""]
        },
        {
            id: "0h",
            details: "Scraped websites, gathering weather data in order to obtain accurate, up-to-date atmospheric data for a research project poised on learning more about microclimates that form alongside train tracks.", 
            categories: [""]
        },
        {
            id: "0c",
            details: "Emailing script which accepts a large unformatted contact list (required due to the nature of the data.) Data is first cleaned and then third-party packages are leveraged to created the desired formatting.", 
            categories: [""]
        },
        {
            id: "0d",
            details: "GUI application which scrapes provided Quizlet links and textbooks (PDFs) to create studying resources", 
            categories: [""]
        },
        {
            id: "0qfqwfqwfe",
            details: "Teacher Assistant, Intro to Programming @ SUNY Plattsburgh. Responsible for assisting professor with instruction during class hours, holding personal lab hours to assistant students.", 
            categories: [""]
        },
        {
            id: "0f",
            details: "HackerRank's Python Leaderboard - Top 10,000", 
            categories: [""]
        },
       
      


    ];

    const javascriptdata = [
        {
            id: "dasdsa",
            details: "Automated trading system operating in real-time on live data, setting buy orders, corroborating datasets to determine profit potential, applying adjusted margins dependent on price, etc.",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used", "Data Management", "Node"]
        },
        {
            id:"dsads", 
            details :"Fingerprinting user information: specifically user identification using geolocation, cookies, in addition to clickthrough rate. Some of which integrated with Google Analytics.", 
            categories: ["Male"]
        },
        {
            id:"hghgshg", 
            details :"Node Package Manager (NPM) package, 'qr-with-logo' has 33,000 downloads to-date. Allows for the creation of QR codes with embedded logos.", 
            categories: ["Male"]
        },
        
        {
            id: "qwdwqwdqwdqwd",
            details: "Flickr API, dynamic photo gallery which retrieves and populates galleries with the contents of Flickr photo albums - permitting client ability to update images on their website by simply adding to a Flickr album", 
            categories: [""]
        },
        {
            id: "gergergerg",
            details: "Scheduling tool offering only available timeslots as they are specified in Google Calendar. Used to schedule appointments, a calendar to facilitate seemless integration between ones business website and their Google calendar. which integrates offers with individual and businesses' GMail accounts GoogleeGoogle Calendar API for management and scheduling of appointments on web application", 
            categories: [""]
        },
        {
            id: "32434",
            details: "Developed infrastructure at Marist College for the issuance of digital credentials. Intern for Marist College to institute digital credentials to students I Trusted Identity project which focused on the decentralized issuance and verification of credentials", 
            categories: [""]
        },
        {
            id: "vbdfbdfb",
            details: "Popup user verification modal for use on external third-party sites permiting convenient integration of IBM's Verified Identity platform. In mind were the Poughkeepsie-based businesses so that verifiable student discounts could be offered to students.", 
            categories: [""]
        },
        {
            id: "nrdbfbe",
            details: "Created mockup solution satisfying shareholder use-case, specifically integration of Marist's Post Office with digital identification credentials using QR codes and a scheme for queued requests, all packaged into user interface ", 
            categories: [""]
        },
        {
            id: "ghfghvrre",
            details: "Designed the visual representation of an ID card which was then, using a creative solution,  screenshotted within the browser and issued as part of the credential. Using CSS and JavaScript.", 
            categories: [""]
        },
        {
            id: "werfwegwhwh",
            details: "Created single-page-style website which employed a dynamic menu bar which adjusted itself corresponding to the current page location. Corresponding with navbar menu are the locations within the page, on-click user is navigated to that section within single-page website.", 
            categories: [""]
        },
        {
            id: "wefwef",
            details: "Scroll speed monitoring and timing used to adjust opacity and visibility of a fixed menu navbar.", 
            categories: [""]
        },
        {
            id: "wewfwefhdfhndfn",
            details: "Functional recreations of websites, for purposes of presentations, demonstrating integration of our solution in real-world environment", 
            categories: [""]
        },
        {
            id: "afafaewgawghh",
            details: "User-switching login screen allowing for multiple accounts to share same device, meanwhile maintaining security", 
            categories: [""]
        },
        {
            id: "3gifbdoiksb",
            details: "Communication, data transmission, between frontend and backend; backend to data storage systems; frontend and external APIs, etc.",
            categories: [""]
        }

    ]

    const javadata = [
        {
            id: "eyteyeety",
            details: "Developed faster, more intuitive method for MySQL integration [JEasyMySQL @ Github]",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "sgfgsh",
            details: "Transferring and accepting uncommon filetypes, such as that of EKG data using data streams. Maven.",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "fmdfghsdbs",
            details: "Compiler, translate an example programming language into machine code. Specifically, the lexer and parser. ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
       

    ]

    const cyberdata = [
        {
            id: "dsdf",
            details: "Reverse engineering APIs, ncovering vulnerabilities in web-based login systems ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "dsfdfewg",
            details: "Discovered endpoints using a variety of techniques, of which included third-party mobile application (particularly Charles)",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "ytjtjyjtj",
            details: "Manipulating requests, modifiying and manufacturing user-sessions and cookies to take advantage of vulnerabilites. ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "fdfhthj",
            details: "Created proxy scraping & checking tool, in addition to using those created by others. ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "liltkitik",
            details: "Recognizing patterns in pattern-generated codes such as serial numbers, gift cards, and promo web codes. ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "ytjtktkkukkutjyjtj",
            details: "SQL injections and cross-site scripting (SQLi and XSS). ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "rtsnnrtyntnyr",
            details: "OWASP and OWASP ZAP",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },

        {
            id: "jembgnryu",
            details: "Modifying files in jailbroken iOS and Playstation",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "kmdmdvdvsdvdghtyum",
            details: "Recognizing potentially correlated leaked user account information across multiple leaked database dumps, idenitifying matching user characteristics, and combining them into a single dataset. Findings were then reported to websites such as 'haveibeenpwned.com' ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "kkekeytck",
            details: "API interference and forgery of packets via interception",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
        {
            id: "kkekeytck",
            details: "Occasionally browse and familiarize myself with lastest reported exploits and Google Dorks. ",
            categories: ["Full-Stack", "Data Management", "Finance", "React", "Third-party API Usage", "Database Used"]
        },
       

    ]

    return (
        <main class={classes.MainContent} style={{height: '100vh'}}>
         <DrawerTitleContainer 
                {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: getHomePageContent(),
                    onchange: {handleChange}
                    
                }}
                />
       
           
            
            
            <SortForm data={[[], pythondata, javascriptdata, javadata, cyberdata]}/>
                  
  
        </main>
    )
}

export default NewProjectsPage

/**
 * <Container maxWidth="md" >
                    

                    <TiltingImageReplica/>
                    <TiltingImageReplica/>
                    
            </Container>
 */


            /**
             * items: [ "Executed app", "Sorted Data"
                            
                            ]
                        }
             */

                        /**
                         * <ExperienceContainer {
                ...     {
                    name: "JavaScript",
                    items: [ "Executed app", "Sorted Data"
                        
                    ]
                    }
            }/>

            <ExperienceContainer {
                ...     {
                    name: "Teamwork & Collaboration",
                    items: [ "Executed app", "Sorted Data"
                        
                    ]
                    }
            }/>

        <ExperienceContainer {
                ...     {
                    name: "Finance",
                    items: [ 
                        "OPTIMIZING SERVER COST by evaluating performance, functionality, cost - in reference to established requirements", 
                        "Sorted Data"
                        
                    ]
                    }
            }/>
                         */