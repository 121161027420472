import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    ItemsDiv: {
        backgroundColor: '#1A2027',
        background: '#1A2027',
    },

    DrawerLogoTextContainer: {
        //width: "4%", 
        
       
    }, 
    DrawerLogoText: {
        
    },
    ServicesContainer: {
        //minHeight: '22.5rem',
        //overflow: 'hidden',
        borderRadius: '6px',
        paddingLeft: '0 !important',
        paddingRight: '0',
        //paddingLeft: '1rem !important',
        //paddingRight: '1rem',
        background: 'linear-gradient(to right, rgb(255, 255, 255), rgb(226, 232, 236))', //'rgba(230, 229, 230, 1)'
        //background: '#ece2e3', //fallback for old browsers
        // background: '-webkit-linear-gradient(to right, #F2E8E9, #ece2e3)',  //Chrome 10-25, Safari 5.1-6
        //background: 'linear-gradient(to right,  #F2E8E9, #ece2e3)' // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
        borderTopColor: "rgb(219, 219, 219)",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderRightColor: "rgb(219, 219, 219)",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "rgb(219, 219, 219)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderLeftColor: "rgb(219, 219, 219)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderImageSource: "initial",
        borderImageSlice: "initial",
        borderImageWidth: "initial",
        borderImageOutset: "initial",
        borderImageRepeat: "initial",
    },

    title_toolbar: {

    },

    ServicesAppBarPaper: {
        backgroundColor: 'transparent',
    },


    LogoSVG: {
        height: '3vh',
        width: '3vh',
    },

    HappelSolutionsLogoContainer : {
        height: '3vh',
        width: '3vw',
        position: 'relative'
    },

    NavigationTooltip: {
        

            //backgroundColor: theme.palette.primary.main,
       
    },

    DetailsTypography: {
        [theme.breakpoints.down(900)]: {
           
            fontSize: '0.8rem!important',
        },
        [theme.breakpoints.up(901)]: {
            fontSize: '1.2rem',
        },
    }
 
}));