import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import useStyles from './styles'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import ComputerIcon from '@material-ui/icons/Computer';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';

import Container from '@mui/material/Container'

import ExperienceContainer from './ExperienceContainer'

const SortForm = ({data}) => {

    const categories = ["Finance", "Project Management", "Software Development", 
    "API", "Full-Stack", "Servers", "React", "Servers", "Data Management", "Problem Solving", "Remote Work", "Database"]
    
    
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });


    const [checked, setChecked] = React.useState([])
    const [foundMatches, setFoundMatches] = React.useState([]);
    const [dos, setDos] = React.useState(0);

    useEffect(() => {
        console.log("Checked Length: " + checked.length);
        
        
    },);

    const isNotMatched = (arr, id) => {
        for (var i = 0; i <= arr.length - 1; i++) {
            var obj = arr[i];
            if (obj.id === id) {return false}
            else {return true}
        } return true;
    }

    const updateFilteredResults = () => {
        console.log("Updating Filtered Results");
        var matches = []; 
        for (var arrs = 0; arrs <= data.length - 1; arrs++) {
            var maingroup = data[arrs];
            for (var i = 0; i <= maingroup.length - 1; i++) {
                var experience = maingroup[i];
                var details = experience.details;
                var categories = experience.categories; 
                console.log("Categoreis: " +categories)
                var cat_id = experience.id;
                //console.log("Current Detail: " + details + ", Current Category: " + categories); 
                
                for (var checked_filters_i = 0; checked_filters_i <= checked.length - 1; checked_filters_i++ ) {
                    var filter = checked[checked_filters_i];
                    console.log("Checking Selected Filter: " + filter);
                    if (categories.includes(filter)) {
                        console.log(" - corresponds with: " + details);
                        if( isNotMatched(matches, cat_id)) {
                            matches.push(experience);
                            console.log("matches.length: " + matches.length)
                        }
                    }
                }
            }
        }
        setFoundMatches(matches);

    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    
    const toggleFilter = (event) => {
        var current_checked = checked;
        console.log("@ToggleFilter before:" + current_checked);
        //Remove category
        if (current_checked.includes(event.target.value)) {
            const index = current_checked.indexOf(event.target.value);
            current_checked.splice(index, 1);
            console.log("@ToggleFilter after:" + current_checked);
            setChecked(current_checked);
            updateFilteredResults(current_checked);
        }
        
        //Add category
        else {
            current_checked.push(event.target.value);
            console.log("@ToggleFilter after:" + current_checked);
            setChecked(current_checked);
            updateFilteredResults(current_checked);
        }

        console.log("Updated Selected Categories: " + current_checked);
        for (let i = 0; i<=current_checked.length-1; i++) {
            console.log(current_checked[i]);
        }

    }

//                 Filter

    const FilterButton = () => {
        return (
            
            <IconButton  
            onClick={toggleDrawer(anchor, true)}
            size="large"
            >
                <FilterListIcon fontSize="inherit"/>
            </IconButton>

            
        );
    }

    /**
     *  style={{
                fontFamily: 'CartographCF-RegularItalic',
                width: '.5em', 
                height: '.5em'
            }}
     */

    const uncheckFilter = (value) => {
            var currentchecked = checked;
            
            const index = checked.indexOf(value);
            currentchecked.splice(index, 1);
            setChecked(currentchecked);
            updateFilteredResults(currentchecked);

        
    }

    const CustomChipLabel = (value) => {
        return (
            <Container style={{display: 'flex'}}>
                <DeleteIcon size="small"/>
                <Typography>Zach</Typography>
            </Container>
        )
    }

    const handleDelete = (h) =>  {
        console.log("Handle Delete: " + h);
        const val = h.target.innerHTML; 
        console.log ("Handle Del Val : "+ val)
        console.log(h)
        setChecked((checked) =>
          checked.filter((x) => x !== val)
        )
      }

    const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          //onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
          <FormControl>
          <FormLabel id="demo-radio-buttons-group-label"><FilterListIcon/>Filter</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                style={{display: 'table-row'}}
                onChange={toggleFilter}
            >
                {categories.map((item, index) => {
                    return (
                        
                        <FormControlLabel  value={item} control={<Radio />} label={item} enabled={checked.includes(item)? true : false} />
                    )
                })}
                
            </RadioGroup>
            </FormControl>
          </List>
          <Divider />
          
        </Box>
      );

    const clearChecked = () => {
        setChecked([]);
    }

    const methods = useForm();
    const classes = useStyles();

    const anchor = "top";
    return (
        <>
        <div>
        
            <React.Fragment key={anchor}>
                <Container id={'FilterButtonDiv'} style={{ display: 'flex', marginBottom: '1rem'}}>
                    
                    <FilterButton />
                       
                           
                    <Button label={"Clear"} onClick={clearChecked}> Clear  </Button>
                    <Stack direction="row" spacing={1}>
                            
                            {checked.map((item,index) => {
                                return (
                                    <Chip style={{display: 'flex', alignSelf: 'center'}} id="hello" name={item} key={index} label={item} value={item} index={index} variant="outlined" deleteIcon={<DeleteIcon />} onClick={handleDelete} />
                                    )
                                })}
                    
                        </Stack>
                </Container>

                
                <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
            >
                {list(anchor)}
            </SwipeableDrawer>
            </React.Fragment>
        
        </div>

        {checked.length > 0 ? (
            <ExperienceContainer 
                
                className={classes.FilteredExperienceContainer}
                {
                    ... {
                        name: "Filtered Results",                                      
                        content: foundMatches,
                }
                }
            /> 
        ) : 
        null
        }   

        { checked.length === 0 ? (

            <>
                <ExperienceContainer 
                    
                        className={classes.ExperienceContainer}
                        {
                            ... {
                                name: "Python",
                                content: data[0]
                        }
                    }
                    />
                    
                    <ExperienceContainer 
                    
                    className={classes.ExperienceContainer}
                    {
                        ... {
                            name: "Javascript",
                            content: data[1]
                    }
                    }
                />

                    <ExperienceContainer 
                    
                    className={classes.ExperienceContainer}
                        {
                            ... {
                                name: "Java",
                                content: data[2]
                        }  }
                    />  

                <ExperienceContainer 
                    
                    className={classes.ExperienceContainer}
                        {
                            ... {
                                name: "Cybersecurity",
                                content: data[3]
                        }  }
                    />  
            </>
        ) : null}

        <FormProvider {...methods}>

        </FormProvider>

        </>
    )
}

export default SortForm
//            <FormLabel id="demo-radio-buttons-group-label">Filter</FormLabel>

/**
onClick={toggleFilter("Female")}
onClick={toggleFilter("Male")}
onClick={toggleFilter("Other")}
onClick={toggleFilter("Full-Stack")}
onClick={toggleFilter("React")}
onClick={toggleFilter("Server")}
**/
//                                        
