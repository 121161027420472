import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
//import Typist from 'react-typist';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import AboutBannerImage from '../../Assets/photos/AboutBanner.png'
//import AAboutBannerImage from '../../../Assets/photos/AboutBannerRocketRecolor.png';
import AboutBannerImageRecolor from '../../assets/AboutBannerRocketRecolor.png';
//import AboutBannerRocket from '../../Assets/photos/AboutBanner-Rocket.png'


const styles = theme => ({

    BannerImageContainer: {
        height: '100%',
        width: '100%',
        marginTop: '1rem',
        //float: 'auto',
        //margin: '3px',
        //padding: '3px',
        display: 'contents',

        [theme.breakpoints.down(900)]: {

        },

        [theme.breakpoints.up(901)]: {

        }

    },

    BannerImage: {
        marginBottom: '-1rem',
        maxWidth: '100%',
        height: 'auto',
        display: 'flex',

    },

    BannerVideo: {
        maxWidth: '100%',
        height: 'auto',
        display: 'flex',
    }



});

function DownArrow(...props) {
    console.log("Down Arrow: ", props);
    return (
        <SvgIcon style={{color: '#000000', position: 'absolute'}}
                 fontSize ={"large"}
                 component={ExpandMoreIcon}
                 id={'HomepageHeaderDownArrow'}
        />
    )
}


class BannerImage extends Component {
    constructor(props) {
        super(props);
        this.state = {renderMsg: false};
        this.myRef = React.createRef();
        this.setHeaderState = async () => {
            this.setState({displayHeadImage: false});
            document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
            localStorage.setItem('headImageShown', 'true');// Has been shown
        };

        this.waitAgain = async () => {
            setTimeout(this.setHeaderState, 500)
        };
        this.waitSetState = async () => {
            await setTimeout(this.waitAgain, 500)
        };
    }


    render () {

        const { classes } = this.props;
//                       <img className={classes.BannerImage} src={'../../Assets/photos/AboutBanner-Rocket.png'} alt={'i'} />
        return (
                   <Paper className={classes.BannerImageContainer}>
                       <img className={classes.BannerImage} src={AboutBannerImageRecolor} alt='balls' />
                   </Paper>
        );
    }


    scrollToMyRef =  async () => {
        this.waitSetState();
        window.scrollTo({
            top: this.myRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };



}
export default withStyles(styles, { withTheme: false })(BannerImage);

/**  Paper and Image Component*/
/*
      <Paper elevation={2}>
                    <img className={classes.TestingImage} src={'../../laptop.jpg'}/>
                </Paper>
 */



/**
 <Divider className={classes.infoSectionEndDivider}/>
 <p className={classes.SubsectionHeader}> Our Location </p>
 <p className={classes.infoSectionBody} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 </p>
 <Divider className={classes.infoSectionEndDivider}/>

 <p className={classes.SubsectionHeader}> Learn More </p>
 <p className={classes.infoSectionBodyFinal} id={'infoSectionBody'}>
 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 sed do eiusmod tempor incididunt ut labore et dolore magna
 aliqua <Link href="/#">Lorem Ipsum</Link> page.
 </p>
 **/