import React, {Component, Fragment, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//import AppToolBarTitle from "./AppToolBarTitle";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";



import useStyles from "./styles";
import FixedDrawerMenu from './FixedDrawerMenu';
// Local Storage -- homeHeaderPreviouslyShown



const TitledContainer = ({details, directory, content, classes}) => {
    
    return (
        <div className="home-body-container"
        style=
        {{
            paddingTop: '10vh',
           
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 0,
        }} 
        id={"home-body-container-before"}>

           

                <FixedDrawerMenu 
                
                

                className={classes.title_toolbar} containerDetailsAndDirectory={
                        {
                            classes: classes,
                            directory: directory,
                            title: details.title,
                            emoji: details.emoji,
                            isMain: true, // ??
                        }
                } />

                

                <Paper  className={classes.ServicesAppBarPaper}>

                    {content}

                </Paper>

            

        </div>
    

    );
}

export default withStyles(useStyles, { withTheme: false })(TitledContainer);

//export default TitleContainer

/**
 * 
 class TitledContainer extends Component{
     
     constructor(props) {
         super(props);
         this.state = {};
        }
        
    componentDidMount() {}

    
    
    
    render () {
        
        const classes = useStyles();
        //const { classes } = this.props;
        const { details } = this.props;
        const { directory } = this.props;
        const { content } = this.props;
        
        //console.log('TitledContainer Props: ', this.props);
        
        
    }
    
    
    
}

*/
//id={'separating-gutter'}
//<Toolbar  /** Separating! **/ />