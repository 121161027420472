import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import Container from '@mui/material/Container'
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import BuildIcon from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ClassIcon from '@material-ui/icons/Class';

import useStyles from './styles'; 
import DrawerTitleContainer from '../../components/FixedDrawerMenu/DrawerTitleContainer';
import {TiltingImageReplica } from '../../components'

import BannerImage from '../../components/BannerImage/BannerImage';
import PresentationTwitterBotBanner from '../../components/BannerImage/PresentationTwitterBotBanner';

const PresentationAndEventsPage = () => {
    const classes = useStyles();
    const container_details = {
        title: 'Presentation and Events',
        emoji: '💬',
    };
    document.getElementsByTagName("title")[0].innerHTML = container_details.title + " | Zach Happel";

    const directory = [
        { title: 'About Me', componentName: 'Intro', path: '/', icon: <AccessibilityNewIcon/> },
        { title: 'Experience', componentName: 'Services', path: '/experience', icon: <BuildIcon/> },
        { title: 'Work History', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/work', icon: <ComputerIcon/> },
        { title: 'Projects', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/projects', icon: <ClassIcon/> },
        { title: 'Presentation and Events', componentName: 'Showcase/AutoRotatingCarouselProp', path: '/presentation-events', icon: <PeopleOutlineIcon/> },
       // { title: 'Other', componentName: 'Contact', path: '/contact', icon: <PermContactCalendarIcon/> },
        
      ]
  
    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    localStorage.setItem('current_page', 'PresentationsAndEventsPage');
    
    const getContent = () => {
        return (

            <React.Fragment>



                <PresentationTwitterBotBanner style={{
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 59%), 1px 4px 1px 0px rgb(0 0 0 / 18%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
                }}/>

                <div className={classes.sectionBackground}>

                    <div className={classes.boxContent}>

                        <div className={classes.subsectionTitleAndBody}>

                          
                            
                            

                            <div className={classes.whatCanWeDoForYouContainer} style={{display: 'inline-block', width: '100%'}}>
                            
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                    <div className={classes.sectionBackgroundWhite}>
                                        <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)'}}>
                                            <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center',marginBottom: '0.5rem',fontFamily: 'AvenirLTStd-Book',}}>
                                                | Coming soon. |
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.sectionBackgroundPink}>
                                            
                                    </div>

                                       

                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                
                            </div>
                        </div>

                    </div>
                </div>


            </React.Fragment>

        )
    };

    const content = getContent();
    
    const [checked, setChecked] = React.useState(false);
    return (
        <>
         <DrawerTitleContainer 
                {... {
                    classes: classes,
                    directory: directory,
                    details: container_details,
                    content: content,
                    onchange: {handleChange}
                    
                }}
                />
       
           
            
            <Container maxWidth="md" >
                    


                
            </Container>
        
        </>
    )
}

export default PresentationAndEventsPage;


/**
 * <div className={classes.epDescriptionIndent1}>
                                <h4 className={classes.epH4}
                                style={{marginLeft:'0rem'}}> Work History </h4>
                                
                            </div>
 */



                            /**
                             * 
                             * 
                             * <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                            IBM Software Engineer Intern | Poughkeepsie, NY
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>

                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book'}}>
                                                IT Aide Intern @ Cold Spring Harbor Laboratory | Cold Spring Harbor, New York
                                            </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book'}}>
                                        Student Data Analyst, Data Science and Analytics Department | Poughkeepsie, New York
                                            </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book'}}>
                                         Neurological, Cybersecurity Phishing Research | Plattsburgh, New York
                                            </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>


                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book'}}>
                                        Microclimates and Toxic/Flammable Chemical Drift Research
                                            </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>

                                <div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)',}}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book'}}>
                                        Center for Cybersecurity and Technology Intern
                                            </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>
                                
                                <Divider style={{marginLeft: '2rem', marginRight: '1rem'}}/>



                            
                                <h4 className={classes.epH4}> Our Goal </h4>
                                <div className={classes.epDescriptionIndent1}
                                     style={{marginTop: '0vh'}}
                                >


<div className={classes.sectionBackgroundWhite}>
                                    <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                        <div className={classes.epDescriptionIndentForOpener} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                            IBM Software Engineer Intern | Poughkeepsie, NY
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.sectionBackgroundPink}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                This internships includes my involvement in a variety of projects. Currently, I am working with an IBM team located in Raleigh, North Carolina on the development and implementation of Trusted Identity: a virtual form of identification that utilizes blockchain as the basis for forming trusted relationships. Future projects include the use of honeypots, the creation of botnets, and the penetration testing of IoT devices.

                                                I will maintain this position throughout this summer, and throughout the following, and final, year of undergraduate schooling.                                            </div>
                                            </div>
                                        </div>
                                        <div className={classes.sectionBackgroundOther}>
                                            <div className={classes.boxContent} style={{ boxShadow: '1px 3px 0 1px rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 19%)', }}>
                                                <div className={classes.epDescriptionIndentForDescription} style={{ textAlign: 'center', marginBottom: '0.5rem', fontFamily: 'AvenirLTStd-Book', }}  >
                                                <div className={classes.List}>  
                                                    <li className={classes.ListItem}> Worked with an IBM team located in Raleigh </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                    <li className={classes.ListItem}> Hello </li> 
                                                </div>
                                                                                          </div>
                                            </div>
                                        </div>

                                </div>



                             */