import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  MainContent: {
    background: ' linear-gradient(90deg, rgba(19,19,29,1) 0%, rgba(56,56,70,1) 19%, rgba(82,82,107,1) 42%, rgba(81,81,106,1) 47%, rgba(76,76,103,1) 54%, rgba(43,43,54,1) 88%, rgba(32,32,46,1) 98%, rgba(26,26,38,1) 100%)',

    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    //overflowX: 'hidden',
  },
  image: {
    maxWidth: '100%',
  },

  ExperienceContainer: {
    marginBottom: '20%'
  },

  FilteredExperienceContainer: {
    
  }
  
}));