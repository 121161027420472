import { makeStyles, fade } from '@material-ui/core/styles';

const drawerWidth = 0;

export default makeStyles((theme) => ({

    NavDiv: {
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: "Open Sans",
        textAlign: 'left',
        padding: '20px',
        maxHeight: '1.75rem'
    },

    NavLogoImage: {
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: "Open Sans",
        textAlign: 'left',
        padding: '20px',
        maxHeight: '1.35rem !important',
        paddingLeft: '1.01rem !important'
    }
    
}));