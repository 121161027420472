import React, { useState, useEffect } from 'react';
//import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Divider } from '@material-ui/core';

import useStyles from './styles';

import nellaheadshot from '../../assets/nellaheadshot1.jpg'
const TiltingImageReplica = () => {
  const [count, setCount] = useState(0);
  const [loaded, setLoaded] = useState(false);


  /**
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `You clicked ${count} times`;
    if (!loaded) {
      checkIfRefreshNeeded();
      console.log("Loaded: " + loaded);
     // document.getElementById("testingFadeUnset").setAttribute('id', 'testingFade');
      setLoaded(true);
      //window.location.reload(true);
    }
  });
 */
  const checkIfRefreshNeeded = () => {

    var revisitedPage = localStorage.getItem("visitedHomePage");
    if (revisitedPage == null) {
      console.log("Revisited is null");
      localStorage.setItem("visitedHomePage", true);
    }

    if (revisitedPage == true) {
      
    }
        //console.log("Visited Home Page: " + revisitedPage);
  }

  const handleCHange = () => {
    console.log("Hi!");
    setCount(count + 1)  ;
    console.log("Loaded: " + loaded);
  }

  
 

  const classes = useStyles();
  

  return (
            
    <div class="wrapper" onClick={handleCHange}>
        <div class="main">
        <div class="primary">

            <section id='testingFadeUnset' style={{}} class="page1">
                    <div id='tilting-image' class="page_container">
                        <h1><a href="#">Zacharie Happel<br/>
                        
                        <small>Software Engineer<br/></small>
                        <small>Centerport, New York</small>
                        
                        </a></h1>
                    </div>
            </section>

        <div class={classes.ParagraphSection}>
          <Divider class={classes.Divider}/>
            <p class={classes.Subtitle}> <b> Resumes serve an essential task but fall short at the behest of brevity.  </b> </p>
            <p class={classes.Content}> <b> With a field as complex as computer science, engineers are bound to engage in a variety of tasks in which their mettle is tested and their skillsets are expanded. However clever you are, there is no true way to display such intricacies in a simple resume. </b> </p>
            <Divider class={classes.Divider}/>
            <p class={classes.Subtitle}> <b> Solving This: </b> </p>
            <p class={classes.Content}> <b>This site will allow me to ellaborate to a significant extent on my development in both a professional and personal capacity. </b></p>
            <Divider class={classes.Divider}/>
            <div class={classes.WorkInProgressBlock}> 
            
              
            <p class={classes.Subtitle}> <b>This site, by the very nature of it, will always be a work in progress. </b> </p>
            <p class={classes.Content}> <b> Even now as I am publishing this site for the first time, much has still yet to be added. A majority of the 'coming soon' addendums will be removed and their sections will populated in the very near future. </b></p>
            </div>
         </div>   
        
        </div>
        </div>
    </div>

  );
};

export default TiltingImageReplica;

//<h2 class={classes.Title}> <b> This Site's Purpose: </b> </h2>
// begetting brevity
// at the behest of brevity /
//<p class={classes.Content}> Solving this, while also providing myself with a medium to post thoughts, recommendations, and write about personal interests of mine, this site will allow me to ellaborate to a significant extent on my development in both a professional and personal capacity.</p>
//<p class={classes.Content}> Engineering are bound to engage in a variety of tasks in which their mettle is tested and their skillsets are expanded. However clever you are, there is no true way to display such intricacies in a simple resume. </p>