import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
  tiltingImage: {
    width: "100%",
  },
  image: {
    maxWidth: '100%',
  },

  ParagraphSection: {
    [theme.breakpoints.down(900)]: {
      marginTop: '4rem',
      fontSize: '2.8rem!important',
    },
    [theme.breakpoints.up(901)]: {
        fontSize: '1.2rem',
    },
  },

  Title: {
    fontFamily: 'AvenirLTStd-Book', 
    color: 'rgb(255,255,255)',
    marginTop: '1.2rem',
    [theme.breakpoints.down(900)]: {
           
      fontSize: '0.95rem!important',
    },
    [theme.breakpoints.up(901)]: {
        fontSize: '1.2rem',
    },
  },

  Subtitle: {
    fontFamily: 'AvenirLTStd-Book', 
    textAlign: '-webkit-left', 
    
    color: '#ccb352',
    
    [theme.breakpoints.down(900)]: {
      marginLeft: '3rem',
      marginRight: '2rem', 
      fontSize: '0.85rem!important',
    },
    [theme.breakpoints.up(901)]: {
      marginLeft: '8rem',
        fontSize: '1.2rem',
    },
  },

  SubtitleNoDescription: {
    fontFamily: 'AvenirLTStd-Book', 
    textAlign: '-webkit-left', 
    marginLeft: '6rem',
    marginRight: '6rem',
    color: '#ccb352',
    
    [theme.breakpoints.down(900)]: {
      marginLeft: '3rem',
      marginRight: '2rem',
           
      fontSize: '0.85rem!important',
    },
    [theme.breakpoints.up(901)]: {
        fontSize: '1.2rem',
    },
  },

  Content: {
    fontFamily: 'AvenirLTStd-Book', 
    textAlign: '-webkit-left', 
    color: '#c8bebe',
    marginLeft: '2rem',
    [theme.breakpoints.down(900)]: {
      marginLeft: '3rem',
      marginRight: '3rem',
      fontSize: '0.9rem!important',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.up(901)]: {
        fontSize: '1.2rem',
        marginLeft: '8rem',
        marginRight: '8rem',
        lineHeight: '2rem',
    },
  },

  WorkInProgressBlock: {
    //display: 'flex',
  },

  Divider: {
    backgroundColor: 'rgb(255 255 255 / 12%)', margin: '-6px', height: '2px', 
    [theme.breakpoints.down(900)]: {
      //margin: '25vw 4vw',
      margin: '9vw 4vw',
    },
    [theme.breakpoints.up(901)]: {
      //margin: '20vw 4vw',
      margin: '7vw 4vw',
    },
  },
  
}));